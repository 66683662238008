@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

:root {
  --scrollbarWidth: 10px;

  --primaryColor: #a92938;
  --secondaryColor: #8f1725;

  background: var(--background);
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: var(--scrollbarWidth);
  height: var(--scrollbarWidth);
  border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #272727;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: var(--scrollbarWidth);
  height: var(--scrollbarWidth);

  background: linear-gradient(
    135deg,
    var(--primaryColor) 0%,
    var(--secondaryColor) 100%
  );
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

@keyframes move-left-right {
  0% {
    transform: translateX(-15px);
  }
  50% {
    transform: translateX(15px);
  }

  to {
    transform: translateX(-15px);
  }
}

body {
  margin: 0;
  font-family: "Lobster", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100vh;
}
